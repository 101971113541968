module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Autoren Website von Rebecca Lehners","short_name":"Rebecca Lehners","start_url":"/","background_color":"#FFFFFF","theme_color":"#935D8C","display":"browser","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"cb844cadfad94dce2f65f9da6c48ad0b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-100},
    },{
      plugin: require('../node_modules/gatsby-plugin-modal-routing/gatsby-browser.js'),
      options: {"plugins":[],"modalProps":{"style":{"overlay":{"position":"fixed","display":"flex","justifyContent":"center","alignItems":"center","top":0,"left":0,"right":0,"bottom":0,"backgroundColor":"rgba(0, 0, 0, 0.75)"},"content":{"position":"static","background":"#fff","padding":"0","borderRadius":"0.25em","border":"none","width":"100%","maxWidth":"993px","margin":"2em","maxHeight":"calc(100vh - 2em)"}}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
